import React, { useState, useEffect } from "react";
import { Button, Form, Container, Row, Col, Dropdown } from "react-bootstrap";
import Slider from "react-slick";
// markup
import Select from 'react-dropdown-select';
import ScrollAnimation from "react-animate-on-scroll";
import { SortBy, Configure, connectCurrentRefinements } from "react-instantsearch-dom"
import { connectMenu, connectRange, connectSearchBox, connectStats, connectToggleRefinement, connectRefinementList, connectSortBy, connectConfigure } from "react-instantsearch-core";
// import SearchResultInputBox from "../SearchResultBox"
import SearchResultInputBox from "../SearchResultBox"
import { removeSpecialChar, hyphenToCaptilize, QueryList } from "../../../components/utils/property-search"
import { isEmpty, get, find } from "lodash"
import { useWindowSize } from '../../../hooks/winodw-size'
import $ from "jquery"
import _ from "lodash"
import { navigate } from "@reach/router";
import "./FilterBlock.scss";
// import SearchInputBox from "../../predictive-search/search-inputbox";

const SearchBox = connectSearchBox(({ aroundLatLng, currentRefinement, refine, query, radius, setRadius, algoliaConfig, mapresetbutton, mapdraggedbyuser }) => {

    const [department, setDepartment] = useState('2');

    const departmentSelect = (e) => { setDepartment(e) }
    const [areaVal, setAreaVal] = useState(query)
    // console.log('query', algoliaConfig)
    var radiusDis = true;
    if (!_.isEmpty(aroundLatLng)) {
        radiusDis = false;
    }
    const [radiusVal, setRadiusVal] = useState(radius)
    const queryList = QueryList();
    const radiusOptions = [
        { value: "", label: "Radius" },
        { value: "3", label: "+ 3 miles" },
        { value: "4", label: "+ 4 miles" },
        { value: "6", label: "+ 6 miles" },
        { value: "8", label: "+ 8 miles" },
        { value: "10", label: "+ 10 miles" },
        // { value: "30", label: "+ 30 miles" },
        // { value: "50", label: "+ 50 miles" },
        // { value: "100", label: "+ 100 miles" }
    ]
    return (
        <div className="search-box results-radius-search-box">
            <div className="search-wrap">
                <SearchResultInputBox
                    // value={areaVal !== 'kent' ? hyphenToCaptilize(areaVal) : ''}
                    value={hyphenToCaptilize(areaVal)}
                    setValue={(searchValue, isSelected) => {
                        //  refine(removeSpecialChar(searchValue))
                        if(mapresetbutton.current != null) {
                            mapresetbutton.current.click();
                        }
                        mapdraggedbyuser(false)
                        if (isSelected) {
                            localStorage.setItem('searchValue', removeSpecialChar(searchValue));
                            localStorage.setItem('searchValueFull', searchValue);
                            localStorage.setItem('searchValueFullName', searchValue);
                            refine('')
                        } else {
                            localStorage.removeItem('searchValue');
                            localStorage.removeItem('searchValueFull');
                            localStorage.removeItem('searchValueFullName');
                            refine(removeSpecialChar(searchValue))
                        }

                        // $("#res-div-one").hide()
                        // $("#res-div-two").hide()
                        // setTimeout(() => {         
                        //     $("#res-div-one").show()                
                        //     $("#res-div-two").show()                
                        // }, 2000);
                        setAreaVal(searchValue)
                    }}
                    placeHolder={`Choose town or area`}
                    areaList={queryList.areaList}
                />
            </div>
            <Select
                name="radius"
                options={radiusOptions}
                values={[{
                    label: radiusVal
                        ? radiusOptions.filter(c => c.value == radiusVal)[0].label
                        : "Radius",
                    value: radiusVal ? radiusVal : "",
                }]}
                placeholder="Radius"
                classNamePrefix={"select-option group-dropdown"}
                isSearchable={false}
                onChange={e => {
                    if(mapresetbutton.current != null) {
                        mapresetbutton.current.click();
                    }
                    setRadius(e[0].value)
                }}
                disabled={radiusDis}
            />
        </div>
    )
});

const styles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
        };
    },
    control: styles => ({
        ...styles,
        backgroundColor: null,
        border: 0,
        outline: 0,
        boxShadow: 'none',
        height: "100%",
        padding: 0
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
        }
    },
    dropdownIndicator: (defaultStyles) => {
        return {
            ...defaultStyles,
            display: "none"
        }
    },
    valueContainer: (styles) => ({
        ...styles,
        padding: 0
    }),
    indicatorSeparator: () => null,
}

// Property Min price
const priceminRangeSale = [
    {
        value: "",
        label: "No Min",
    },
    {
        value: "50000",
        label: "£50,000",
    },
    {
        value: "60000",
        label: "£60,000",
    },
    {
        value: "70000",
        label: "£70,000",
    },
    {
        value: "80000",
        label: "£80,000",
    },
    {
        value: "90000",
        label: "£90,000",
    },
    {
        value: "100000",
        label: "£100,000",
    },
    {
        value: "125000",
        label: "£125,000",
    },
    {
        value: "150000",
        label: "£150,000",
    },
    {
        value: "175000",
        label: "£175,000",
    },
    {
        value: "200000",
        label: "£200,000",
    },
    {
        value: "225000",
        label: "£225,000",
    },
    {
        value: "250000",
        label: "£250,000",
    },
    {
        value: "275000",
        label: "£275,000",
    },
    {
        value: "300000",
        label: "£300,000",
    },
    {
        value: "350000",
        label: "£350,000",
    },
    {
        value: "400000",
        label: "£400,000",
    },
    {
        value: "450000",
        label: "£450,000",
    },
    {
        value: "500000",
        label: "£500,000",
    },
    {
        value: "600000",
        label: "£600,000",
    },
    {
        value: "700000",
        label: "£700,000",
    },
    {
        value: "800000",
        label: "£800,000",
    },
    {
        value: "900000",
        label: "£900,000",
    },
    {
        value: "1000000",
        label: "£1,000,000",
    },
    {
        value: "1500000",
        label: "£1,500,000+",
    },
]

const pricemaxRangeSale = [
    {
        value: "",
        label: "No Max",
    },
    {
        value: "50000",
        label: "£50,000",
    },
    {
        value: "60000",
        label: "£60,000",
    },
    {
        value: "70000",
        label: "£70,000",
    },
    {
        value: "80000",
        label: "£80,000",
    },
    {
        value: "90000",
        label: "£90,000",
    },
    {
        value: "100000",
        label: "£100,000",
    },
    {
        value: "125000",
        label: "£125,000",
    },
    {
        value: "150000",
        label: "£150,000",
    },
    {
        value: "175000",
        label: "£175,000",
    },
    {
        value: "200000",
        label: "£200,000",
    },
    {
        value: "225000",
        label: "£225,000",
    },
    {
        value: "250000",
        label: "£250,000",
    },
    {
        value: "275000",
        label: "£275,000",
    },
    {
        value: "300000",
        label: "£300,000",
    },
    {
        value: "350000",
        label: "£350,000",
    },
    {
        value: "400000",
        label: "£400,000",
    },
    {
        value: "450000",
        label: "£450,000",
    },
    {
        value: "500000",
        label: "£500,000",
    },
    {
        value: "600000",
        label: "£600,000",
    },
    {
        value: "700000",
        label: "£700,000",
    },
    {
        value: "800000",
        label: "£800,000",
    },
    {
        value: "900000",
        label: "£900,000",
    },
    {
        value: "1000000",
        label: "£1,000,000",
    },
    {
        value: "1500000",
        label: "£1,500,000+",
    },
]


var minpriceval = minpriceval || ""
var maxpriceval = maxpriceval || ""


const minpriceSelectbox = ({ currentRefinement, refine, value, sales }) => {
    let selectedArr = priceminRangeSale;
    let minPriceRange = selectedArr;
    if (currentRefinement.max !== undefined) {
        minPriceRange = selectedArr.filter(
            x => parseInt(x.value) < parseInt(currentRefinement.max) || x.value === ""
        )
    }
    var minpval = minPriceRange.find(obj => obj.value == currentRefinement.min) || minPriceRange.find(obj => obj.value == value)

    return (
        <div className="filter-option">
            <Select
                name="minprice"
                // styles={styles}
                values={minpval ? [minpval] : []}
                options={minPriceRange}
                placeholder="Min Price"
                searchable={false}
                classNamePrefix={"select-opt"}
                onChange={event => {
                    if(event.length > 0) {
                        minpriceval = event[0].value
                        if (event[0].value === "") {
                            refine({
                                // min: event[0].value,
                                max: currentRefinement.max
                            })
                        } else {
                            refine({
                                min: event[0].value,
                                max: currentRefinement.max,
                            })
                        }
                    }
                }}
            >
            </Select>
        </div>
    )
}


const maxpriceSelectbox = ({ currentRefinement, refine, value, sales }) => {
    let selecedArr = pricemaxRangeSale;
    // let maxPriceRange = selecedArr.sort((a, b) => parseInt(b.value) - parseInt(a.value));
    let maxPriceRange = selecedArr;
    let maxpricedisable = ""
    if (currentRefinement.min !== undefined) {
        maxPriceRange = selecedArr.filter(
            x => parseInt(x.value) > parseInt(currentRefinement.min) || x.value === ""
        )
    }
    if (currentRefinement.min == "10000000") {
        maxpricedisable = "disabled"
    }
    var maxpval = maxPriceRange.find(obj => obj.value == currentRefinement.max) || maxPriceRange.find(obj => obj.value == value)
    return (
        <div className="filter-option">
            <Select
                name="maxprice"
                // disabled={maxpricedisable}
                values={maxpval ? [maxpval] : []}
                defaultValue={{ value: "0", label: "Max Price" }}
                placeholder="Max Price"
                options={maxPriceRange}
                // classNamePrefix={"select-opt"}
                searchable={false}
                onChange={event => {
                    if(event.length > 0) {
                        maxpriceval = event[0].value
                        if (event[0].value === "") {
                            refine({
                                min: currentRefinement.min,
                                // max: event[0].value
                            })
                        } else {
                            refine({
                                min: currentRefinement.min,
                                max: event[0].value,
                            })
                        }
                    }
                }}
            >
            </Select>
        </div>
    )
}

const beds = [
    { label: "Beds", value: "" },
    { label: "Studio", value: "0" },
    { label: "1+", value: "1" },
    { label: "2+", value: "2" },
    { label: "3+", value: "3" },
    { label: "4+", value: "4" },
    { label: "5+", value: "5" },
]

// Property Bedrooms
const bedroomSelectbox = ({ currentRefinement, refine, value }) => {
    let bedroomvalselect = ""
    if (currentRefinement.min !== undefined) {
        bedroomvalselect = currentRefinement.min
    }
    var bedval = beds.find(obj => obj.value == bedroomvalselect) || beds.find(obj => obj.value == bedroomvalselect)
    return (
        <div className="filter-option">
            <Select
                searchable={false}
                options={beds}
                multi={false}
                // value={bedroomvalselect}
                placeholder="Beds"
                // classNamePrefix={"select-opt"}
                values={bedval ? [bedval] : []}
                onChange={event => {
                    if (event[0].value === "") {
                        refine(event[0].value)
                    } else if (event[0].value === "0") {
                        refine({
                            max: event[0].value,
                        })
                    } else {
                        refine({
                            min: event[0].value,
                        })
                    }
                }}
            />
        </div>
    )
}

const buildings = [
    { label: "Show all", value: "" },
    { label: "Houses", value: "house" },
    { label: "Flats", value: "flat" },
    { label: "Bungalows", value: "bungalows" }
]

// Property Type
const propertyTypeFilter = ({ currentRefinement, refine, value }) => {
    let propertyType = "";
    if (currentRefinement != null) {
        propertyType = currentRefinement
    }
    var typeval = propertyType ? buildings.find(obj => obj.value == propertyType) || buildings.find(obj => obj.value == propertyType) : buildings.find(obj => obj.value == value) || beds.find(obj => obj.value == value)
    return (
        <Select
            searchable={false}
            options={buildings}
            placeholder="Type"
            // classNamePrefix={"select-opt"}
            values={typeval ? [typeval] : []}
            onChange={event => refine(event[0].value)}
        />
    )
}


const CustomminpriceSelectbox = connectRange(minpriceSelectbox);
const CustommaxpriceSelectbox = connectRange(maxpriceSelectbox);
const CustombedroomSelectbox = connectRange(bedroomSelectbox);
const CustomPropertyMenu = connectMenu(propertyTypeFilter);
// const CustomPopular = connectMenu(popularFilter);

const FilterBlock = (props) => {
    const [show, setShow] = useState(false);
    const showMenu = () => {
        setShow(!show);

        $(".search-template").toggleClass("filter-active")
    };
    const [department, setDepartment] = useState('+2 Miles');
    const departmentSelect = (e) => { setDepartment(e) }
    const queryList = QueryList();
    const [filterOpen, setfilterOpen] = useState(false);
    const [promotions, setPromotions] = useState(queryList.promotionList);

    const filterClick = () => {
        setfilterOpen(!filterOpen);
    };
    const [configvalues, setConfigvalues] = useState({
        hitsPerPage: 20,
        // aroundLatLng: '51.6384, -1.23815',
        //  aroundRadius: 15000,
    })
    const settings = {
        dots: false,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        centerMode: false,
        variableWidth: true,
        centerPadding: "18px",
    };

    const query = props.query;
    const min = props.min === "" ? undefined : parseInt(props.min);
    const max = props.max === "" ? undefined : parseInt(props.max);
    const bedrooms = props.bedrooms === "" ? undefined : parseInt(props.bedrooms);
    const building = props.building;
    const popular = props.popular;

    const [windowWidth] = useWindowSize();

    function fitlerDisplay(e) {
        $(".dropdown-exp").toggleClass("active");
    }

    // Apply search mobile
    const applySearchMobile = () => {
        setShow(!show);
        $(".search-template").removeClass("filter-active");
        $(".filter").removeClass("filter-active");
        $("filter-field").removeClass("show-fliter");
    }
    // Apply search mobile

    // Sales & Rent
    function onClickBuy(e) {
        navigate(`/new-homes-for-sale/in-kent/`)
    }

    function onClickRent(e) {
        navigate(`/new-homes-for-sale/in-kent/`)
    }
    // Sales & Rent




    const AllTypes = [

        { value: "Anytime", label: "Anytime" },
        { value: "24 hours", label: "24 hours" },
        { value: "Last 3 days", label: "Last 3 days" },
        { value: "Last 7 days", label: "Last 7 days" },
        { value: "Last 14 days", label: "Last 14 days" },
    ];

    return (
        <React.Fragment>
            {/* <Configure {...configvalues} /> */}
            <div className={show ? "filter filter-active" : "filter"}>
                <button className="filter-show"> Refine Search <i className={show ? "icon-minue-white icon-plus-white " : "icon-plus-white "} onClick={showMenu}></i></button>
                <div className={show ? "filter-field show-fliter " : "filter-field "}>
                    <Container>
                        <Row>
                            <Col className="filter-fixed ">
                                <Form className="form-data mb-0">
                                    {/* <div className="radio-form">
                                        <Form.Check
                                            type="radio"
                                            label="Buy"
                                            name="propertyRadio"
                                            id="propertyRadio1"
                                            className="buy-radio"
                                            checked={props.searchType == "sales" ? true : false}
                                            onClick={() => onClickBuy()}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="Rent"
                                            name="propertyRadio"
                                            className="rent-radio"
                                            id="propertyRadio2"
                                            checked={props.searchType == "lettings" ? true : false}
                                            onClick={() => onClickRent()}
                                        />
                                    </div> */}
                                    <SearchBox aroundLatLng={props.aroundLatLng} defaultRefinement={query} query={query} radius={props.radius} setRadius={props.changeRadius} showLoadingIndicator={false} algoliaConfig={props.algoliaConfig} mapresetbutton={props.buttonrest} mapdraggedbyuser={props.mapdraggedbyuser} />
                                    {/* <SearchInputBox/> */}
                                    <div className="box-wrapper">
                                        <div className="select-boxes buy-select">
                                            <label>Property Type</label>
                                            <CustomPropertyMenu attribute="building" value={building} defaultRefinement={building} />
                                        </div>

                                        <div className="select-boxes min-price-select">
                                            <label>Min Price</label>
                                            <CustomminpriceSelectbox attribute="min_price" value={min} defaultRefinement={{ min: min, max: max }} sales={props.sales} />
                                        </div>

                                        <div className="select-boxes max-price-select">
                                            <label>Max Price</label>
                                            <CustommaxpriceSelectbox attribute="min_price" value={max} defaultRefinement={{ min: min, max: max }} sales={props.sales} />
                                        </div>
                                        <div className="select-boxes bedroom-select">
                                            <label>Bedrooms</label>
                                            <CustombedroomSelectbox attribute="bedrooms" value={bedrooms} defaultRefinement={{ min: bedrooms }} />

                                        </div>

                                        <div className="d-md-none d-block">
                                            <a href="javascript:void(0)" className="btn btn-secondary w-100" onClick={applySearchMobile}>Apply</a>
                                        </div>
                                    </div>
                                    {/* <div className="clear-refinements">
                                        <CustomClearRefinements />
                                    </div> */}
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container>
                    <Row>
                        <Col>
                            <div className="w-100">
                                <div className="search-result-short d-flex align-items-center justify-content-between">
                                    <div className="filter-short d-none">
                                        {/* <div className="switch-button d-none d-xl-block">
                                            <label for="toggle-button">
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => props.includeSold(e.target.checked)}
                                                    id="toggle-button"
                                                />
                                                <span className="toggle-switch">
                                                    <span className="toggle-switch-bar"></span>
                                                </span>
                                                <span className="toggle-text">Include {props.sales ? "Sold": "Let"}</span>
                                            </label>
                                        </div> */}
                                        {/* <a className="price-short-filter" onClick={() => props.setMapview(false)} href="javascript:;">
                                            <i className={`icon-list ${props.mapView == true ? "active" : ""}`}></i>
                                            <span className="ms-0 toggle-text"> List</span>
                                        </a>
                                        <a className="price-short-filter" onClick={() => props.setMapview(true)} href="javascript:;">
                                                <i className={`icon-map ${props.mapView == true ? "active" : ""}`}></i>
                                                <span className="ms-0 toggle-text">Map</span>
                                        </a> */}
                                        {/* <div className="select-boxes select-boxes-buy recent d-none d-md-block">
                                             <div className="drop-down box d-md-block d-none">
                                                <SortBy
                                                    defaultRefinement={props.index_name+""}
                                                    className="search-sory-by form-control h-auto border-0"
                                                    items={[
                                                        { value: props.index_name+"", label: "Recently added" },
                                                        { value: props.index_name+"_price_desc", label: "Highest Price" },
                                                        { value: props.index_name+"_price_asc", label: "Lowest Price" },
                                                    ]}
                                                    disabled
                                                />
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default connectStats(FilterBlock);
