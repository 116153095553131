import _ from "lodash"
import { useStaticQuery, graphql } from "gatsby";
const METER_VAL = 1609.34;


const meterToMiles = (val) => {
  return Math.round(parseInt(val) / METER_VAL)
}

const milesToMeter = (val) => {
  return Math.round(parseInt(val) * METER_VAL)
}

const getLatLan = (item) => {
  if (item?.latitude && item?.longitude) {
    return `${item.latitude},${item.longitude}`
  }
  return '';
}

const removeSpecialChar = (val) => {
  if (!_.isEmpty(val)) {
    return val.replace(/[^\w\s]/gi, ' ').replace(/\s\s+/g, ' ').replace(/ /gi, '-').toLowerCase()
  }
  return val;
}
const hyphenToCaptilize = (val) => {
  if (!_.isEmpty(val)) {
    return _.startCase(_.toLower(val.replace(/-/gi, ' ')));
  }
  return val;
} 

const isAreaMatch = (areaList, area) => {  
  return _.find(areaList, function (obj) {
    return removeSpecialChar(obj.name) === area;
  }) 
}

const QueryList = () => {
  const data = useStaticQuery(graphql`
  query {
    allArea {
      nodes {
        id
        _id
        name
        districtCountyTown
        town
        county
        district
      }
    }
  }
`);
  return {areaList: data.allArea.nodes}
} 


export {
  meterToMiles,
  milesToMeter,
  getLatLan,
  removeSpecialChar,
  hyphenToCaptilize,
  QueryList,
  isAreaMatch

}
