import React, { useState } from "react";
import { Link } from "gatsby";
import "./PropertyCard.scss";
// import PlayVideo from "../../Components/Play/PlayVideo";

import NoImage from "../../../images/no-image.jpg"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import parse from "html-react-parser";
const PropertyCard = (props) => {

  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
  }
  return (
    <React.Fragment>
      <div className={`property-card img-zoom ${props.showcased_property ? 'box-blue' : props.spotlight_property ? 'box-yellow' : ''}`} id={props.objectID}>
        <div className="properties-wrapper">
          <Link to={`${props.url}`} className="img-link">
            <picture>
              {props.image_url ?
                <LazyLoadImage
                  wrapperProps={{ style: { display: "block" } }}
                  alt={'property Image'}
                  effect="blur"
                  src={props.image_url} />
                :
                <img src={NoImage} alt="img" />
              }
              {/* <img src={props.ImgLarge} alt="img" /> */}
            </picture>
            <div className="btn-group-wrap">
              <button className="btn-camera"><i className="icon-camera-gallery"></i> <span>1/{props.imageCount}</span></button>
              {props.virtual_tour &&
                <button className="btn-play-circle"><i className="icon-play-circle"></i></button>
              }
              <button className="btn-flor"><i className="icon-flor"></i> </button>
            </div>
          </Link>
        </div>
        <div className="about-properties">
          <div className="properties-info">
            <h2 onClick={handleClick}>

              <Link to={`${props.url}`} className="img-link">
                {props.PropertyHeading}
              </Link>
              <i className={show ? "icon-heart d-lg-none icon-heart-fill" : "icon-heart d-lg-none"}></i>
            </h2>
            {
              props.min_price && props.max_price &&
              <span className="property-price d-flex align-items-center">
                {props.PropertyPricePrefix} £{props.min_price ? props.min_price.toLocaleString() : '0'} - £{props.max_price ? props.max_price.toLocaleString() : '0'}
              </span>
            }
            {
              props.PropertyText &&
              <p>{parse(props.PropertyText)}</p>
            }

          </div>

          <div className="properties-icons">
          </div>
        </div>

      </div>
    </React.Fragment>
  );
};

export default PropertyCard;
